
import {
  computed,
  defineComponent,
  ref,
  onMounted,
  h,
  onBeforeUnmount,
} from "vue";
import { OptionProps } from "@/types";
import deepClone from "@/utils/deepClone";
import { useStore } from "vuex";
import {
  ColumnsProps,
  APIDataProps,
  AutoTableCellProps,
  AutoTableColProps,
} from "@/types";
import { expandProps } from "@/views/Budget/types";
import { formatData, getGroup, guid, handleChangePre } from "@/utils/index";
import { message } from "ant-design-vue";

import { searchVehicle, searchNstGroupName } from "@/API/program";
import useEventbus from "@/hooks/claim/useEventbus";
import { clearCellData } from "@/utils/pgm";
import { useTableOperation } from "@/hooks/Program/useTableOperation";
import { useAllCheck } from "@/hooks/Program/useAllCheck";
import { useTableAddRowChildren } from "@/hooks/Program/useTableAddRowChildren";
import moment from "moment";
// I/E的下拉，写死的，后端没有给
const IESelect: OptionProps[] = [
  {
    display: "Include",
    id: "11",
  },
  {
    display: "Exclude",
    id: "12",
  },
];

export default defineComponent({
  components: {
    // 下拉框里的全选
    VNodes: (_, { attrs }) => {
      return attrs.vnodes;
    },
  },
  setup() {
    const store = useStore();
    const { customOn, customEmit } = useEventbus();
    // 组数据
    const offerGrops = ref<APIDataProps[]>([]);
    // 表头
    const cols = ref<AutoTableColProps[]>([]);
    // 列的数据
    const fetching = ref<boolean>(false);

    // 得到colums, 和头部
    const { getColums, columns } = useTableOperation();

    const { checked, currentChecked } = useAllCheck();
    const { addNewInclude, addNullExclude } = useTableAddRowChildren();

    const kufriChecked = ref(true);

    // 增加一组
    const handleAddGroup = (): void => {
      const param: APIDataProps = {
        group: [],
        groupId: guid(),
        groupName: `Group ${offerGrops.value.length + 1}`,
        tableCell: [
          addNullExclude(addNewInclude(cols.value), offerGrops.value),
        ],
        tableCol: offerGrops.value[0].tableCol,
      };
      offerGrops.value.push(param);

      // 需要更新一下vuex，因为下面的Pre-condition的G1要一样
      store.state.pgmData.eligibility.vehicle = offerGrops.value;
      store.commit("updatePgmGroup", getGroup());
    };

    // 折叠
    const isFlag = ref(false);

    // 自定义展开icon图标-----------------------展开折叠部分
    const clickExpand = (props: expandProps, e: Element) => {
      props.onExpand(props.record, e);
    };
    // 得到exclude行
    const getExcludeRow = (
      includeRow: AutoTableCellProps,
      excludeRow: AutoTableCellProps
    ) => {
      for (const i in excludeRow) {
        if (excludeRow[i].constructor === Object && i !== "ACT_0") {
          excludeRow[i].val = includeRow[i].val;
        }
      }
      return excludeRow;
    };

    // 点击添加Exclude, 增加新的一行，Exclude
    const clickAddExclude = (props: any) => {
      const param = deepClone(props.record);
      param.id = guid();
      if (offerGrops.value.length > 0) {
        for (const key of offerGrops.value) {
          for (const val of key.tableCell) {
            if (val.id === props.record.parent) {
              // parent
              param.parent = props.record.parent;
              if (val.children) val.children.push(getExcludeRow(val, param));

              break;
            }
          }
        }
      }
    };

    const expandedRowKeys = computed(() => {
      const keyMaps = offerGrops.value.reduce((obj: any, item) => {
        return {
          ...obj,
          [item.groupId as string]: item.tableCell.map((row: any) => {
            return row.id;
          }),
        };
      }, {});
      return store.state.pgmData.readOnly ? keyMaps : [];
    });

    // 自定义展开icon
    const expandIcon = (props: expandProps) => {
      // if (store.state.pgmData.readOnly) {
      //   props.expanded = true;
      //   props.onExpand(props.record, undefined);
      // }
      if (props.record.children && props.record.children.length > 0) {
        if (props.expanded) {
          //有数据-展开时候图标
          return h("i", {
            class: "font_family icon-xiangxia cursor-p m-r-20",
            onClick: (e: Element) => {
              clickExpand(props, e);
            },
          });
        } else {
          //有数据-未展开时候图标
          return h("i", {
            class: "font_family icon-xiangyou cursor-p m-r-20",
            onClick: (e: Element) => {
              clickExpand(props, e);
            },
          });
        }
      } else {
        //无数据-图标
        return h("i", {
          class: "font_family icon-tianjia11 cursor-p m-r-20",
          style: { width: "16px", height: "1px", display: "inline-block" },
          onClick: () => {
            clickAddExclude(props);
          },
        });
      }
    };

    // 删除一行
    const handleDelete = (item: APIDataProps, record: AutoTableCellProps) => {
      if (item.tableCell && item.tableCell.length > 0) {
        // 当删除的是父级时
        if (record.parent === "0") {
          if (item.tableCell.length > 1) {
            item.tableCell = item.tableCell.filter(
              (val: AutoTableCellProps) => record.id !== val.id
            );
          } else {
            message.warning("This is the last one!");
          }
        } else {
          // 当删除的是子级时
          let targetObj: AutoTableCellProps = record;
          for (const val of item.tableCell) {
            if (record.parent === val.id) {
              targetObj = val;
              break;
            }
          }
          if (
            targetObj &&
            targetObj.children &&
            targetObj.children.length > 1
          ) {
            item.tableCell = item.tableCell.map((val: AutoTableCellProps) => {
              if (val.children && val.children.length > 1) {
                val.children = val.children.filter(
                  (vals: AutoTableCellProps) => record.id !== vals.id
                );
              }
              return val;
            });
          } else {
            message.warning("This is the last one!");
          }
        }
      }
    };

    // 自动加一行
    const autoAddInclude = (
      row: AutoTableCellProps,
      group: any,
      index: number
    ) => {
      if (group.tableCell.length - 1 === index) {
        group.tableCell.push(
          addNullExclude(addNewInclude(cols.value), offerGrops.value)
        );
      }
    };

    // select获得焦点时,先清空之前下拉框的值，再赋相关的值。col:当前列，cellSelect：当前组
    // 需要判断brand先选择
    const handleFocus = (
      index: number,
      colCur: string,
      row: AutoTableCellProps,
      group: APIDataProps,
      vals: any
    ) => {
      autoAddInclude(row, group, index);
      // 先清空，下拉框的值，再赋值
      group.tableCellSelect = [];
      if (
        (row[group.tableCol[1].id].val as string[]).length < 1 &&
        colCur !== group.tableCol[1].id
      ) {
        message.error("Please choose brand first!");
        return;
      }
      // 1。加载loading
      fetching.value = true;
      const params = {
        bu: store.state.pgmData.businessUnit.selected,
        id: row.id,
        row: row,
        target: colCur,
      };
      searchVehicle(params).then((res) => {
        fetching.value = false;
        group.tableCellSelect = res;
        currentChecked(group.tableCellSelect, vals.val);
      });
    };
    const sortDate = (dataList: any) => {
      return dataList.sort(function (a: any, b: any) {
        if (a > b) {
          return 1;
        } else if (a === b) {
          return 0;
        } else {
          return -1;
        }
      });
    };
    const getPeriod = () => {
      customEmit("updateperiod");
      const period = store.state.pgmData.eligibility.period.tableCell;
      const fromList = [];
      const toList = [];
      for (let i = 0; i < period.length; i++) {
        const current = period[i];
        const from = current["FROM"].val;
        const to = current["TO"].val;
        if (to !== "" && from !== "") {
          fromList.push(moment(from));
          toList.push(moment(to));
        }
      }
      const sortedFrom = sortDate(fromList);
      const sortedTo = sortDate(toList).reverse();
      if (sortedFrom.lenght === 0 || sortedTo.length === 0) {
        const currentDate = moment().format("yyyy-MM");
        return { minFrom: currentDate, maxTo: currentDate };
      } else {
        return {
          minFrom: sortedFrom[0].format("yyyy-MM"),
          maxTo: sortedTo[0].format("yyyy-MM"),
        };
      }
    };
    const handleNstFocus = (
      index: number,
      colCur: string,
      row: AutoTableCellProps,
      group: APIDataProps,
      vals: any
    ) => {
      autoAddInclude(row, group, index);
      // 先清空，下拉框的值，再赋值
      group.tableCellSelect = [];
      if (
        (row[group.tableCol[1].id].val as string[]).length < 1 &&
        colCur !== group.tableCol[1].id
      ) {
        message.error("Please choose brand first!");
        return;
      }
      fetching.value = true;
      const { minFrom, maxTo } = getPeriod();
      const params = {
        bu: store.state.pgmData.businessUnit.selected,
        id: row.id,
        row: row,
        target: colCur,
        nstInclude0: kufriChecked.value,
        periodFrom: minFrom,
        periodTo: maxTo,
      };
      searchNstGroupName(params).then((res) => {
        fetching.value = false;
        group.tableCellSelect = res;
        currentChecked(group.tableCellSelect, vals.val);
      });
    };

    // 变成不可编辑
    // 2.take父级的值到子级
    const takeChildrenCol = (col: string, row: AutoTableCellProps) => {
      // 如果当前行是父级，1.下面的.children里面的当前列都需要改成不可编辑,2.并且子级全部和父级一样
      if (row.parent === "0" && row.children) {
        row.children.forEach((item: AutoTableCellProps) => {
          for (const i in item) {
            // 如果是对象的才能更新
            if (item[i].constructor === Object && i !== "ACT_0") {
              item[i].val = row[i].val;
              // exclude有值不能编辑，否值能编辑
              if (row[i].val.length > 0) {
                item[i].isEdit = false;
              } else {
                item[i].isEdit = true;
              }
            }
          }
        });
      }
    };

    // 选择下拉后的值的改变:1.需要清空后面的值，2.需要take上面的值,3.如果是最后一行，里面有一个值改变了都需加一行
    const handleChangeSelect = (
      index: number,
      col: string,
      row: AutoTableCellProps,
      group: APIDataProps
    ) => {
      if (row[col].val.length > 0) row[col].isAuto = false;
      clearCellData(index, group.tableCol, row);
      takeChildrenCol(col, row);
    };

    // 下拉里面的全选，取消全选
    const handleAllCheck = (
      index: number,
      col: string,
      row: AutoTableCellProps,
      group: APIDataProps
    ) => {
      checked.value = !checked.value;
      if (checked.value) {
        row[col].val = group.tableCellSelect;
      } else {
        row[col].val = [];
      }
      handleChangeSelect(index, col, row, group);
    };

    // 失去焦点后，取消全选恢复
    const handleBlur = (): void => {
      checked.value = false;
    };

    // 父级监听子级更新数据到vuex
    customOn("updatevehicle", () => {
      store.state.pgmData.eligibility.vehicle = offerGrops.value;
    });

    // 展开清空
    const dropdownVisibleChange = () => {
      offerGrops.value.forEach((item: APIDataProps) => {
        item.tableCellSelect = [];
      });
    };

    // 得到要渲染的数据树形结构
    const formatTree = (): void => {
      offerGrops.value.forEach((item: APIDataProps) => {
        // 1.组成树形结构
        // 2.需要一进入就要自动加一行空白行,因为数据结构给的是平铺的，我们显示要用children
        if (item.tableCell) {
          item.tableCell.push(
            addNullExclude(addNewInclude(cols.value), offerGrops.value)
          );
        }
      });
    };

    // 初始化需要：1.得到表头，2.页面渲染用的数据 3.得到colums, 和头部，4.// 得到要渲染的数据树形结构 5.格式化数据,加入isEdit,和isAuto
    const init = () => {
      // 1.得到表头
      cols.value = store.state.pgmData.eligibility.vehicle[0].tableCol;
      // 2.页面渲染用的数据
      offerGrops.value = store.state.pgmData.eligibility.vehicle;
      // 3.得到colums, 和头部
      getColums(store.state.pgmData.eligibility.vehicle[0], true, {
        index: 0,
        width: "170px",
      });
      // 4.得到要渲染的数据树形结构
      formatTree();
      // 5.格式化数据,加入isEdit,和isAuto
      formatData(offerGrops.value);
      store.commit("updatePgmGroup", getGroup());
    };

    onMounted(() => {
      if (store.state.pgmData.eligibility.vehicle.length > 0) init();
    });

    return {
      offerGrops,
      columns,
      cols,
      handleAllCheck,
      checked,
      IESelect,
      handleDelete,
      isFlag,
      handleAddGroup,
      expandIcon,
      handleFocus,
      handleChangeSelect,
      dropdownVisibleChange,
      handleBlur,
      fetching,
      handleChangePre,
      takeChildrenCol,
      kufriChecked,
      handleNstFocus,
      expandedRowKeys,
    };
  },
});
