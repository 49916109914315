
import { defineComponent, ref, onMounted, PropType } from 'vue'
import { message } from "ant-design-vue";
import { useStore } from 'vuex'
import { refreshFormula, FormulaProps, FormulaGroupProps } from "@/API/program"
import { getAttachmentSetting, importProgramAttachment, deleteProgramAttachment, downloadProgramAttachment } from '@/API/setting'
import useEventBus from '@/utils/mitt'
import { AttachementProps } from '@/views/Program/types'
import DrawerUpload from '@/components/DrawerUpload.vue'
 import blobDownload from "@/utils/blobDownload";
 import useEventbus from "@/hooks/claim/useEventbus"
const columns = [
    {
        key: 'documentTypeEn',
        dataIndex: 'documentTypeEn',
        title: 'Attachment List',
        slots: {customRender: 'documentTypeEn' },
        width: '50%',
    },
    {
        dataIndex: 'internalMark',
        key: 'internalMark',
        title: 'For Program Approval',
        slots: {customRender: 'internalMark' },
        width: 200,
    },
    {
        dataIndex: 'externalMark',
        title: 'For Program Release',
        slots: {  customRender: 'externalMark' },
        key: 'externalMark',
        width: 200,
    },
    {
        title: 'operation',
        dataIndex: 'operation',
        slots: { customRender: 'operation' },
    }
]
export default defineComponent({
    name: '',
    props: {
        text: {
            default: () => [],
            type: Array as PropType<AttachementProps[]>
        },
        offer: {
            default: '',
            type: String
        },
        keys: {
            default: '',
            type: String
        }
    },
    components: {
        DrawerUpload,
    },
    setup(props, { emit }) {
        const { customOn } = useEventbus()
        const store = useStore()
        const tableData = ref<AttachementProps[]>([])
        const visible = ref<boolean>(false)
        const isFlag = ref<boolean>(false)
        const rowData = ref(store.state.pgmData.programId)
        const handleRef = () => {
             const params = {
                buId: store.state.pgmData.businessUnit.selected,
                offerTypes: Object.keys(store.state.pgmData.offer),
                programId: store.state.pgmData.programId
            }
            getAttachmentSetting(params).then(res => {
                tableData.value = res
                emit('update:note', tableData.value)
            })

        }
        const getAttachement = () => {
            store.state.pgmData.offer[props.offer].attachments = tableData.value
        }
        customOn('updateattachement', getAttachement)
        const handleChange = () => {
            emit('update:note', tableData.value)
        }
        const updateTable = (attchment: AttachementProps[]) => {
            tableData.value = attchment
        }
        const handleDelete = (record: AttachementProps, index: number) => {
            deleteProgramAttachment(record, store.state.pgmData.programId).then((res) => {
                tableData.value = res
            })
        }

        const handleDown = (record: AttachementProps) => {
            blobDownload({url: '/pgapi/program/design/download_program_attachment', method: 'post', params: record})
        }
        onMounted(() => {
            tableData.value = props.text
        })
        return {
            tableData,
            handleRef,
            isFlag,
            handleChange,
            columns,
            visible,
            updateTable,
            rowData,
            importProgramAttachment,
            handleDelete,
            handleDown
        }
    }
})
