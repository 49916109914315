import { ref } from "vue";
import {
  AutoTableColProps,
  APIDataProps,
  AutoTableCellProps,
  ColumnsProps,
  OptionProps,
} from "@/types";
import { useStore } from "vuex";
import useEventbus from "@/hooks/claim/useEventbus";
import { searchOptions } from "@/API/program";
export const useTableOperation = () => {
  const store = useStore();
  const { customEmit } = useEventbus();

  // 表格表头的渲染用格式
  const columns = ref<ColumnsProps[]>([]);
  // 显示在表头的的删除按钮
  const showDelArr = ref<ColumnsProps[]>([]);
  // Measure组
  const showMeasureArr = ref<ColumnsProps[]>([]);
  const showMeasureSecArr = ref<ColumnsProps[]>([]);
  // 表头显示checkBox

  // 得到colums
  /*
    @param: precondition 数据来源
    @param: operation 是否有operation列， Vehicle, Dealer, Period有，其它的没有
    */
  const getColums = (
    precondition: APIDataProps,
    operation?: boolean,
    colWidth?: { index: number; width: string }
  ) => {
    columns.value = [];
    showDelArr.value = [];
    showMeasureArr.value = [];
    showMeasureSecArr.value = [];
    // Property 'tableCol' does not exist on type 'never[]':断言成any
    if ("tableCol" in precondition && precondition.tableCol.length > 0) {
      // 找到是否带有添加按钮
      console.log(precondition)
      const insuranceKey = precondition.tableCol.find(
        (item: AutoTableColProps) =>
          item.display === "Insurance total support amount"
      );
      const isVehicle = precondition.tableCol.some(
        (item: AutoTableColProps) =>
          item.display === "Include/Exclude"
      );
      precondition.tableCol.forEach(
        (item: AutoTableColProps, index: number) => {
          let params: ColumnsProps = {
            title: item.display,
            dataIndex: item.id,
            slots: { customRender: item.id },
          };
          // option:[],length > 0只有当有下拉选择，才出现measure下拉选框
          if (item.option && item.option.length > 0) {
            params = {
              dataIndex: item.id,
              slots: {
                customRender: item.id,
                title: "customMeasure" + item.id,
                col: item,
                measure: item.selected,
              },
            };
            showMeasureArr.value.push(params);
          }
          // 是否带有添加按钮
          if (insuranceKey) {
            if (item.group === insuranceKey.id && item.id !== insuranceKey.id) {
              params = {
                dataIndex: item.id,
                slots: {
                  customRender: item.id,
                  title: "customAdd" + item.id,
                  col: item,
                  measure: item.selected || item.display,
                },
              };
              showMeasureSecArr.value.push(params);
            }
          }

          // 带有小删除按钮
          if (item.deletable) {
            params = {
              // title: item.display,
              dataIndex: item.id,
              // slots里面的title需要唯一，上面模板里的才能渲染不
              slots: {
                customRender: item.id,
                title: "customDel" + item.id,
                display: item.display,
                col: item,
              },
            };
            showDelArr.value.push(params);
          }
          // 如果是第一列，就是240px,如果是最后一列不固定宽度
          params.width =
            colWidth && index === colWidth.index
              ? colWidth.width
              : index === precondition.tableCol.length - 1
              ? operation
                ? "200px"
                : ""
              : "200px";
          if (item.id === "Vehicle.nstGroupName" && isVehicle) {
            if (params.slots) {
              params.slots.title = "customNstTitle";
            }
            params.title = undefined;
            params.width = "250px";
          }
          columns.value.push(params);
        }
      );
      // 如果传了operation，就有operation列
      if (operation) {
        const paramsOP = {
          title: "Operation",
          dataIndex: "operation",
          slots: { customRender: "operation" },
        };
        columns.value.push(paramsOP);
      }
    }
  };

  const getGroupId = (precondition: APIDataProps) => {
    const arr: string[] = [];
    if (precondition.group) {
      precondition.group.forEach((item: OptionProps) => {
        if (item.selected) {
          arr.push(item.id);
        }
      });
    }
    return arr;
  };

  const fetching = ref<boolean>(false);
  // 获取焦点
  const handleFocus = (
    id: string,
    row: AutoTableCellProps,
    precondition: APIDataProps,
    autoInsetRow?: Function,
    key?: string
  ) => {
    // 1。加载loading
    fetching.value = true;
    precondition.tableCellSelect = [];
    customEmit("updateperiod");
    const params = {
      eligibility: store.state.pgmData.eligibility,
      groupId: key
        ? (precondition.group as any)
            .filter((item: OptionProps) => item.selected)
            .map((items: OptionProps) => items.id)
        : [precondition.groupId],
      row: row,
      target: id,
      customer: store.state.pgmData.customer,
      bu: store.state.pgmData.businessUnit.selected,
    };
    searchOptions(params).then((res) => {
      fetching.value = false;
      precondition.tableCellSelect = res;
    });
    if (autoInsetRow)
      autoInsetRow(precondition.tableCell, precondition.tableCol, row);
  };
  return {
    columns,
    showDelArr,
    showMeasureArr,
    showMeasureSecArr,
    getColums,
    fetching,
    handleFocus,
  };
};
