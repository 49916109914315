
import { defineComponent, ref, PropType, watch } from 'vue'
import SelectTable from '@/views/Program/components/SelectTable.vue'
import { CategoryProps, APIDataEditProps, APIDataProps, ColumnsProps } from '@/types'
import PreconditionVehicleList from './PreconditionVehicleList.vue'
import { useStore } from 'vuex'
import { getGroup } from '@/utils/index'

import { pgmaddColumn } from "@/API/program"
import { message } from 'ant-design-vue';
import { guid } from '@/utils';
import { useInsetCol } from '@/hooks/Program/useInsetCol'
import deepClone from '@/utils/deepClone'
import { useEditGroupTitle } from '@/hooks/Program/useEditGroupTitle'
// 最后一列
export default defineComponent({
    components: { 
        SelectTable, 
        PreconditionVehicleList 
    },
    props: {
        vehicleLicense: {
            type: Array as PropType<APIDataProps[]>,
            default: () => {
                return []
            }
        },
        offer: {
            type: String,
            default: ''
        }
    },
    setup (props) {
        const store = useStore()
        // 是否可以编辑
        // 表格列的定义
        const columns = ref<ColumnsProps[]>([])
        

        const { valateGroupTitle } = useEditGroupTitle()

        // 选择了的行
        const selectedRowKeys = ref<string[]>([])
        const onSelectChange = (selected: string[]) => {
            selectedRowKeys.value = selected
        }

        
        // 新增一组:1.首先需要拿到vehicle，有多少组。
        const handleAddGroup = (): void => {
            const gid = guid()
            const param: APIDataProps = {
                group: [...getGroup()],
                groupId: gid,
                groupName: '',
                tableId: gid,
                tableName: '',
                tableCol: [], // 因为这里不一定要加什么
                tableCell: [],
                tableCellSelect: []
            };
            (props.vehicleLicense as any).push(param)
            
        }
        const { selectVisible, handleInsetCol } = useInsetCol()
        const submitAdd = () => {
            pgmaddColumn(store.state.pgmInsetCol).then(res => {
                const index = Number(sessionStorage.getItem('indexTable'))
                store.state.pgmData.offer[props.offer].vehicleLicense[index] = res
            })
        }

        const isFlag = ref(false)

        const handleDelGroup = (index: number) => {
            const { vehicleLicense } = props
            vehicleLicense.splice(index, 1)
        }
        
        watch(() => store.state.pgmGroup, () => {
            const { vehicleLicense } = props
            for (const item of vehicleLicense) {
                if (item.group) {
                        for ( let i = 0; i < store.state.pgmGroup.length; i++) {
                            const obj = store.state.pgmGroup[i];
                            const num = obj.display;
                            let isExist = false;
                            for (let j = 0; j < item.group.length; j++) {
                                const aj = item.group[j];
                                const n = aj.display;
                                if (n == num) {
                                    isExist = true;
                                    break;
                                }
                            }
                        if(!isExist){
                            item.group.push(deepClone(obj));
                        }
                    }
                }
            }
        })
        
        return {
            valateGroupTitle,
            handleInsetCol,
            selectVisible,
            isFlag,
            columns,
            selectedRowKeys,
            onSelectChange,
            handleAddGroup,
            submitAdd,
            handleDelGroup
        }
    }
})
